.ErrorPopup {
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: -500px;
  left: 50%;
  transform: translate(-50%, 0);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 26px 52px;

  transition: all .3s;
  -moz-transition: all .3s;
  -ms-transition: all .3s;
  -webkit-transition: all .3s;

  z-index: 8;

  img {
    width: 28px;
    height: 28px;
    margin-right: 11px;
  }

  &.visible {
    top: 0;
  }

  &.error {
    background: #FF3F3F1F;
    border: 1px solid #FF171E;

    p {
      color: #FF171E;
    }
  }

    &.success {
      background: #092A16;
      border: 1px solid #3AC461;

      p {
        color: #3AC461;
      }
    }

    &.waiting {
      background: #191919;
      border: 1px solid #626262;

      p {
        color: #D9D9D9;
      }
    }

  .Text {
    font-size: 14px;
    font-weight: 500;
    line-height: 12.6px;
    letter-spacing: 0.005em;
  }
}