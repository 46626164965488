.switch {
    width: 62px;
    height: 31px;
    background-color: #000;
    border-radius: 30px;
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 5px;
    transition: justify-content 0.3s;
  }
  
  .circle {
    width: 23px;
    height: 23px;
    background-color:#0d57cf;
    border-radius: 50%;
    transition: background-color 0.5s, transform 0.3s;
  }
  
  .on .circle {
    background-color: green;
    transform: translateX(0);
  }
  
  .on {
    justify-content: flex-end;
  }
  