.PreviewContainer {
  width: 100%;
  max-width: 430px;
  display: flex;
  flex-direction: row-reverse;
}



.HeaderPreview {
  display: flex;
  justify-content: center;
}

.InfoDownload {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  margin-left: 10px;

   &_NameLand {
     width: 100%;
     max-width: 283px;
     padding: 0 10px;
     background: #0E0E0E;
     border-bottom-left-radius: 10px;
     border-bottom-right-radius: 10px;
     margin-right: 10px;

     &_Text {
       text-align: center;
       font-size: 20px;
       line-height: 23px;
       font-weight: normal;
       padding: 14px 0;
       overflow: hidden;
       white-space: nowrap;
       text-overflow: ellipsis;
     }
   }

  &_SendLand {
    width: 62px;
    height: 62px;
    background: rgba(230,66,71,.2);
    border-radius: 10px;
    cursor: pointer;
    border: 1px solid red;

    &_Button {
      width: 100%;
      height: 100%;
      display: flex;
      padding: 10px;
      align-items: center;
      justify-content: center;

      .IconSend {
        width: 34px;
        height: 34px;
        object-fit: contain;
      }
    }
  }
}

.SwitchDesignPreview {
  border: 1px solid #3B3B3D;
  border-radius: 30px;
}

.TitleSwitcher {
  font-size: 13px;
  line-height: 17px;
  text-align: center;
  margin-top: 25px;
  margin-bottom: 10px;
}