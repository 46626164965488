.InputField {
  display: inline-block;
  width: 100%;
  max-width: 280px;
  padding: 13px 15px;
  font-size: 15px;
  border-radius: 10px;
  border: 1px solid #363738;
  outline: none;
  background: #262626;
  color: #fff;

  &_error {
    border-color: #FF171E !important;
  }
}